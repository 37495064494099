<template>
    <NoButtonModal :open='open' @close='cancel' id='the_new_client_dropdown_modal'>
        <button class='close-corner-x' type='button' @click.prevent='cancel'>
            <span class='material-icons-outlined dark-text'>close</span>
        </button>
        <h1 class="font-20">Attach Member Files</h1>
        <h3>{{name}}</h3>
        <table v-if='files.length > 0' class="attach-file-search">
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Form Name</th>
<!--                    <th>Created By</th>-->
    <!--                <th>Preview</th>-->

                </tr>
            </thead>
            <tbody>
                <tr v-for="(file, index) in files" @click='getFile(file, index)' :key='index'>
                    <td>
                        <input type='checkbox' class="color" v-model="selectedFiles" :id="'file_box' + index" :value='file' @click='$event.stopPropagation()'/>
                        &nbsp;
                        <label :for="'file_box' + index">{{dayjs(file.date).format('MM/DD/YY')}}</label>
<!--                        {{file.dayt_create}}-->
                    </td>
                    <td>

                        {{file.file_name}}
<!--                        {{file.form_name}}-->
                    </td>
<!--                    <td>-->
<!--&lt;!&ndash;                        {{file.signed_by}}&ndash;&gt;-->
<!--                    </td>-->

                    <td>
                        <button>View</button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div v-if='!files || files.length <= 0'>
            No files found for this client.
        </div>
        <div class="align-right">
            <button class="secondary" type='button' @click='cancel'>Cancel</button>
            <button class="primary" type='button' @click='attach'>Attach</button>
        </div>
<!--    <select type='checkbox' v-model='selectedFiles' >-->
    </NoButtonModal>
</template>

<script>
    // import NoButtonModal from
    import NoButtonModal from '@/components/general/modals/NoButtonModal';
    import { documents, file } from '@/util/apiRequests';
    import dayjs from '@/util/dayjs';

    import { openPdf } from '@/util/pdf';
    import { openImage } from '@/util/image';
    import { saveFile } from '@/util/genericUtilityFunctions';

    export default {
        name: 'AttachClientFilesList.vue',
        props: {
            client: {
                type: Object,
            },
            open: {
                type: Boolean,
                required: true
            },
            currentlyClientSelectedFiles: {
                type: Array,
                default: () => []
                // required: true
            },
        },
        components: {
            NoButtonModal
        },
        data() {
            return {
                dayjs,
                files: [],
                selectedFiles: [],
            };
        },
        methods: {
            selectFile(file, index) {
                // this.selectedFiles[index] = file.selected;

            },
            async getFiles() {
                try {
                    // const res = await this.$api.post(clients.getSharedFiles(this.record_id));
                    let criteria = {
                        // filter: { 'files.client_id': [String(this.client.id)]  },
                        filter: { 'files.client_id': [String(this.client.id)], file_type: ['pdf'], form_data_id: ['!0'] },
                        sort: { ['files.dayt_create']: 'Descending' },
                        page: { num_per_page: '10000', page_num: 1 }
                    };
                    const res = await this.$api.post(documents.getList(), { criteria: criteria });
                    if (res.data?.rows?.length > 0) {
                        this.files = res.data.rows;
                    }else {
                        this.files = [];
                    }
                } catch (err) {
                    // this.$toasted.error('Could not retrieve list of shared files');
                }
            },
            async getFile(f, index) {
                if (f.id) {
                    let result = await this.$api.get(file.getFile(f.id));
                    if (result.data.file.file_type == 'pdf' || result.data.file.file_type == '.pdf') {
                        await openPdf(result.data?.file?.Body, result.data?.file?.file_name);
                    } else if (result.data.file.originalContentType.includes('image')) {
                        openImage(result.data?.file?.Body);
                    } else {
                        saveFile(
                            result.data?.file?.Body,
                            result.data?.file?.originalContentType,
                            result.data?.file?.file_name
                        );
                    }
                } else {
                    this.$toasted.error('Could not retrieve file');
                }
            },
            resetForm(action, payload) {
                this.$emit('close', action, payload);
            },
            cancel() {
                this.resetForm('cancel', null);
            },
            attach() {
                this.resetForm('attach', this.selectedFiles);
            },

        },
        mounted() {

        },
        computed: {
            name() {
                return this.client?.name;
            },
            // selectedFiles() {
            //     return this.files.filter(f => f.selected);
            // }
        },
        watch: {
            client: function(newVal, oldVal) {
                if(!newVal){
                    this.files = [];
                }else {
                    this.getFiles();
                }
            },
            currentlyClientSelectedFiles: function(newVal, oldVal) {
                this.selectedFiles = newVal;
            },

        }

    };
</script>

<style scoped>

</style>