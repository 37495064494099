<template>
    <div>
        <span class="blue text-button right-5" @click="openModal"><span class="material-icons-outlined">contact_mail</span></span>
        <vIfWorkableModal v-if="open" @close="closeModal">
            <!-- If the columns for the ChirpList inside the EmailAddressList are changed at all,
                 it will break the css for the modal. See Nate if that happens.  -->
            <EmailAddressList
                ref="addressList"
                :hideSelectColumn=false
                :only-one-client='modalClientId ? true : false'
                @addSelectedRecords="handleAddSelectedRecords"
            />
        </vIfWorkableModal>
    </div>
</template>

<script>
import EmailAddressList from "../../email/EmailAddressList";
import vIfWorkableModal from "./vIfWorkableModal"

export default {
    name: "EmailAddressBookModal",
    components: { EmailAddressList, vIfWorkableModal },
    props: {
        modalClientId: {
            type: Number,
            default: null
        },
    },
    data() {
        return {
            open: false,
        }
    },
    methods: {
        openModal() {
            this.open = true;
        },
        closeModal() {
            this.open = false;
        },
        handleAddSelectedRecords(e) {
            this.open = false;
            this.$emit("addSelectedRecords", e);
        }
    },
}
</script>
