<template>
    <NoButtonModal :open='open' @close='cancel' id='the_new_client_dropdown_modal' class="attach-file-modal">
        <button class='close-corner-x' type='button' @click.prevent='cancel'>
            <span class='material-icons-outlined dark-text'>close</span>
        </button>
        <h2>What do you want to attach?</h2>
        <div class='flex align-center bottom-10'>
            <input type='radio' class="color" v-model='attachType'  name='file_explorer' id='fileExplorer' value='file_explorer'>
            <label for='fileExplorer' class='pad-l-10 pointer'>Files saved on this computer</label>
        </div>
        <div class="left-30 bottom-20" v-show="attachType == 'file_explorer'">
            <!-- dropzone -->
            <div id="upload-container"
                 v-if='showFileExplorer == true'
            >
                <ejs-uploader
                    id="fileupload"
                    name="UploadFiles"
                    ref="fileupload"
                    :autoUpload="false"
                    :buttons="button"
                    :maxFileSize="fileValidation.maxSizeBytes"
                    :selected="onFileSelect"
                    :files='normalFilesCurrentlySelected'
                    :removing='onRemoveFile'
                ></ejs-uploader>
                <span>{{ errors.files[0] }}</span>
            </div>
        </div>
        <div class='flex bottom-10'>
            <input type='radio' class="color" v-model='attachType'  name='client_files' id='clientFiles' value='client' :disabled='moreThanOneClient'>
            <label for='clientFiles' class='pad-l-10 pointer'>Client chart files in <b>{{companyName}}</b>
                <div class="dark-red"><i>{{moreThanOneClient ? 'Disabled: More than one client is a recipient of this email.' :''}}</i></div>
            </label>
        </div>
        <div class="left-30 bottom-20" v-if="attachType == 'client'">
            <Multiselect
                v-model="selectedClient"
                :options="clientOptions"
                track-by="id"
                :custom-label="clientDesc"
                placeholder="Search clients"
                :disabled='client ? true : false'
            />
        </div>
        <div class="align-right">
            <button class='secondary' type='button' @click.prevent='cancel'>Cancel</button>
            <button class="primary" type="button" @click='search' v-if='selectedClient && attachType == "client"'>Search</button>
        </div>


    <AttachClientFilesList :client='selectedClient' @close='handleClientAttachedFiles' :open='showAttachClientFilesList' :currently-client-selected-files='currentlyClientAttachedFiles'/>
    </NoButtonModal>

</template>

<script>
    import AttachClientFilesList from '@/components/clientFiles/AttachClientFilesList.vue';
    import NoButtonModal from '@/components/general/modals/NoButtonModal';
    import { companies } from '@/util/apiRequests';
    import { UploaderPlugin } from '@syncfusion/ej2-vue-inputs';

    export default {
        name: 'AttachEmailDialog',
        components: { AttachClientFilesList, NoButtonModal, UploaderPlugin },
        props: {
            companyName: {
                type: String,
                // required: true
            },
            client: {
                type: Object,
                default: null
                // required: true
            },
            open: {
                type: Boolean,
                default: false
            },
            moreThanOneClient: {
                type: Boolean,
                default: false
            },
            currentlyClientAttachedFiles: {
                type: Array,
                default: () => []
            },
            normalFilesCurrentlySelected: {
                type: Array,
                default: () => []
            },
            showFileExplorer: {
                type: Boolean,
                default: false
            },
            // resyncFiles: {
            //     type: Boolean,
            //     default: false
            // },

        },
        data() {
            return {
                attachType: 'file_explorer',
                selectedClient: null,
                clientOptions: [],
                showAttachClientFilesList: false,
                //EJS-Uploader
                button: {
                    browse: 'Browse'
                },
                //EJS-Uploader
                fileValidation: {
                    maxSizeBytes: 7340032, // Sum of file sizes
                    disallowedExtensions: ['exe']
                },
                //EJS-Uploader
                errors: {
                    files: []
                },
                // resyncFiles: false,
            }
        },
        methods: {
            search(){
              this.showAttachClientFilesList = true;
            },
            clientDesc({ name }) {
                if (name) {
                    name = name.split(' ').reverse();
                    name[0] = `${name[0]},`;
                    return name.join(' ');
                }
                return 'no name';
            },
            createEscListener(self) {
                return function(e) {
                    if (e.key === 'Escape' && self.open) {
                        this.reason = "";
                        self.$emit('close', 'cancel', null);
                    }
                };
            },
            async add() {
                this.resetForm('submit', null);
            },
            cancel() {
                this.resetForm('cancel', null);
            },
            resetForm(action, payload) {
                this.$emit('close', action, payload);
            },

            handleClientAttachedFiles(action, payload){
                //files
                this.showAttachClientFilesList = false;
                //take the attached files and add them to the email
                if(action === 'attach'){
                    if(this.client === null){
                        payload.client = this.selectedClient;
                        payload.clientAttachedFiles = payload;
                        this.$emit('close', 'attachClientFilesAndNewClient', payload);
                    }else {
                        this.$emit('close', 'attachingClientFiles', payload);
                    }
                }
                if(action === 'cancel'){
                    //this.$emit('close', action, payload);
                }

            },
            //EJS-Uploader
            onFileSelect(args) {
                let files = args.filesData;
                files = files.concat(this.getFiles());
                let totalSize = 0;
                this.errors.files = [];
                for (let file of files) {
                    if (this.fileValidation.disallowedExtensions.indexOf(file.type) !== -1) {
                        this.errors.files.push(`"${file.type}" file type is not allowed`);
                    }
                    totalSize += file.size;
                }
                if (totalSize > this.fileValidation.maxSizeBytes) {
                    this.errors.files.push('Total selected file size exceeds limit of 7 MB');
                }
                if (this.errors.files.length > 0) {
                    args.cancel = true;
                    args.isModified = true;
                }else {
                    //remove everything after the period
                    files = files.map((element) => {
                        element.name = element.name.split('.')[0];
                        return element;
                    });
                    this.$emit('close', 'attachingNormalFiles', files);
                }
            },
            //EJS-Uploader
            resetFileUploadInput() {
                // this.$refs.fileupload.clearAll();
            },
            //EJS-Uploader
            getFiles() {
                let files = this.$refs.fileupload.getFilesData();
                return files;
            },
            //EJS-Uploader
            onRemoveFile(args) {
                try {
                    this.$emit('removeNormalFiles', args.filesData[0]);
                }catch(e){
                    console.log(e);
                }
            },

        },
        watch: {

           client: {
                handler(newVal, oldVal) {
                    if(newVal) {
                        this.selectedClient = newVal;
                        if (this.clientOptions.some(c => c.id === parseInt(newVal.id))) {
                            this.selectedClient = this.clientOptions.find((client) => client.id === parseInt(newVal.id));

                        }
                    }else {
                        this.selectedClient = null;
                    }
                    if(this.moreThanOneClient){
                        this.selectedClient = null;
                        this.attachType = 'file_explorer';
                    }
                },
                deep: true,
                immediate: true

           }
        },
        async created() {
            try {
                let result = await this.$api.get(companies.getClients(this.$store.state.user.company_id));
                this.clientOptions = result.data;
            } catch (error) {
                // this.$toasted.error('Could not retrieve clients.');
            }
        },
        mounted() {},
        beforeDestroy() {
            window.removeEventListener('keydown', this.escListener);
        },
    };
</script>

<style scoped>

</style>