<template>
    <NoButtonModal :open='open' @close='cancel' id='the_new_client_dropdown_modal'>
        <button class='close-corner-x' type='button' @click.prevent='cancel'>
            <span class='material-icons-outlined dark-text'>close</span>
        </button>
        <div class='flex'>
            <div class='right-20'>
                <span class='material-icons-outlined red-text large-icon'>error_outline</span>
            </div>
            <div>
                <h2 class='bottom-5'>Client Files Will Be Removed</h2>
                <p>{{description}}</p>
            </div>

        </div>

        <div class='fullwidth align-right'>
            <button class='secondary' type='button' @click.prevent='cancel'>Cancel</button>
            <button class='primary' type='button' @click='add'>Add Client</button>
        </div>


    </NoButtonModal>
</template>

<script>
    import NoButtonModal from '@/components/general/modals/NoButtonModal';
    import { ValidationObserver } from 'vee-validate';
    export default {
        name: 'ClientAttachFilesWarningModal',
        components: { NoButtonModal, ValidationObserver },
        props: {
            open: {
                type: Boolean,
                required: true
            },
            description: {
                default: 'Member files currently attached to this member will be removed if an additional member is added. Would you like to continue and add this client?',
                required: false
            },
            payload: {
                default: null
            }
        },
        data() {
            return {
            };
        },
        methods: {

            createEscListener(self) {
                return function(e) {
                    if (e.key === 'Escape' && self.open) {
                        this.reason = "";
                        self.$emit('close', 'cancel', null);
                    }
                };
            },
            async add() {
                this.resetForm('submit', null);
            },
            cancel() {
                this.resetForm('cancel', null);
            },
            resetForm(action, payload) {
                this.$emit('close', action, payload);
            }

        },
        async created() {},
        mounted() {},
        beforeDestroy() {
            window.removeEventListener('keydown', this.escListener);
        },

    };
</script>

<style scoped>

</style>