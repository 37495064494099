<template>
    <div id="email-editor" class="email-modal max1000">
        <h1 class="bottom-20">{{ header }}</h1>
        <button class="bottom-20" @click="goto_address_book" v-if="0 && !modal_client_id">Address Book</button>

        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
            <form id="emailForm" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="reset">
                <div class="email-from">
                    <Input id="fromInput" v-model="email.from" name="From" label="From" type="email" disabled />
                </div>
                <div class="email-to flex center">
                    <label for="">To</label>
                    <EmailAddressBookModal
                        @addSelectedRecords="(e) => handleAddSelectedRecordsForEmailProperty(e, 'toAddresses')"
                        :modal-client-id='modal_client_id'
                        v-if="!modal_client_id"
                    />
                    <div class="flex center flex-1">  
                        <Multiselect
                            v-model="email.toAddresses"
                            :options="modal_client_id ? emailContacts.filter(e => {
                                if(modal_client_guardian_email && e.email === modal_client_guardian_email) {
                                    return true;
                                }
                                if(!modal_client_guardian_email && e.id == modal_client_id && e.type == 'client') {
                                    return true;
                                }
                                return false
                            }) : emailContacts"
                            name="toAddressesSelect"
                            track-by="track_id"
                            :custom-label="emailOptionLabel"
                            :multiple="true"
                            :taggable="true"
                            @select="handleToEmailSelectEvent"
                            @remove="handleToEmailRemoveEvent"
                            @tag="(e) => validateThenAddEmailAddress('toAddresses', 'to', e)"
                            placeholder="Search..."
                            :disabled="modal_client_id ? true : false"
                        />
                    </div>
                    <span>{{ errors.to[0] }}</span>
                </div>

                <div class="email-to flex center" v-if='showCC'>
                    <label for="">CC</label>
                    <div class="flex center flex-1">
                        <EmailAddressBookModal
                            @addSelectedRecords="(e) => handleAddSelectedRecordsForEmailProperty(e, 'ccAddresses')"
                            :modal-client-id='modal_client_id'
                        />
                        <Multiselect
                            v-model="email.ccAddresses"
                            :options="emailContacts"
                            name="ccAddressesSelect"
                            track-by="track_id"
                            :custom-label="emailOptionLabel"
                            :multiple="true"
                            :taggable="true"
                            @tag="(e) => validateThenAddEmailAddress('ccAddresses', 'cc', e)"
                            placeholder=" Search..."
                            @select='handleCCEmailChange'
                        />
                    </div>
                    <span>{{ errors.cc[0] }}</span>
                </div>
                <div class="email-to flex center" v-if='showBCC'>
                    <label for="">BCC</label>
                    <div class="flex center flex-1">
                        <EmailAddressBookModal
                            @addSelectedRecords="(e) => handleAddSelectedRecordsForEmailProperty(e, 'bccAddresses')"
                            :modal-client-id='modal_client_id'
                        />
                        <Multiselect
                            v-model="email.bccAddresses"
                            :options="emailContacts"
                            name="bccAddressesSelect"
                            track-by="track_id"
                            :custom-label="emailOptionLabel"
                            :multiple="true"
                            :taggable="true"
                            @tag="(e) => validateThenAddEmailAddress('bccAddresses', 'bcc', e)"
                            placeholder=" Search..."
                            @select='handleBCCEmailChange'
                        />
                    </div>
                    <span>{{ errors.bcc[0] }}</span>
                </div>
                <div class='flex-center cc-bcc-btns bottom-20'>
                    <button @click="showCC = !showCC" class="dark-text no-bg weight-600" v-if='showCC == false'>
                        <span class="material-icons purple">add_box</span> CC
                    </button>
                    <button @click="showBCC = !showBCC" class="dark-text no-bg weight-600" v-if='showBCC == false'>
                        <span class="material-icons purple">add_box</span> BCC
                    </button>
                    <div class='flex center top-10' v-if='!modal_client_id'>
                        <div>&nbsp;
                            <Checkbox
                                id='share-to-client-chart'
                                value='0'
                                v-model='shareToClientChart'
                                class="inline"
                            />
                            Log email to a client’s timeline (*emails log to timeline automatically if a client is a recipient of this email)
                        </div>
                    </div>
                    <div class='email-to top-15' v-if='!modal_client_id && shareToClientChart'>
                        <label for="">Client*</label>
                        <div class="flex center flex-1">
                            <Multiselect
                                v-model='clientToChart'
                                :options='clientContacts'
                                track-by="id"
                                :loading="loadingAssociatedClient"
                                :internal-search="false"
                                :clear-on-select="false"
                                @search-change="debounceFindAssociateClient"
                                placeholder='Search for a client'
                                open-direction="bottom"
                                :custom-label='associatedClientLabel'
                                @select='handleShareToClientChange'
                            >


                                <template slot="singleLabel" slot-scope="{ option }">
                                    <div style='display: flex'>
                                        <span style='width: 80%;'>{{associatedClientLabel(option)}}</span>
                                        <div style='width: 20%; display: flex; justify-content: flex-end; z-index: 99'>
                                            <span class="material-icons" @mousedown.prevent.stop='removeShareToClient($event)' style='cursor: pointer'>highlight_off</span>
                                        </div>
                                    </div>
                                </template>


                                <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                                <span slot='noOptions'>Search for a client...</span>

                            </Multiselect>
                        </div>
                    </div>
                </div>
                <div class="template flex center">
                    <label>Use Template</label>
                    <select id="templateSelect" @change="pick_template($event.target.value)">
                        <option value="">Pick a template</option>
                        <option v-for="(option, index) in list_templates" :key="index" :value="option.id">
                            {{ option.email_template_title }}
                        </option>
                    </select>
                </div>

                <div id="inputs-container" class="top-30 email-subject">
                    <Input
                        id="subjectInput"
                        v-model="email.subject"
                        name="Subject"
                        label="Subject"
                        type="text"
                        placeholder=""
                        required
                    />
                </div>
                <div id="defaultRTE">
                    <Wysiwyg v-model="email.html" @rawText="(text) => (email.text = text)" />
                </div>
                <p />
<!--                <div id="upload-container">-->
<!--                    <ejs-uploader-->
<!--                        id="fileupload"-->
<!--                        name="UploadFiles"-->
<!--                        ref="fileupload"-->
<!--                        :autoUpload="false"-->
<!--                        :buttons="button"-->
<!--                        :maxFileSize="fileValidation.maxSizeBytes"-->
<!--                        :selected="onFileSelect"-->
<!--                    ></ejs-uploader>-->
<!--                    <span>{{ errors.files[0] }}</span>-->
<!--                </div>-->
                <div class="attached-files" v-if='attachedFiles'>
                    <div class="pad-5" v-for='(file, index) in attachedFiles' :key='index'>
                        <button class="right-10 no-bg pointer rad-round pad-5" @click='removeFile(file)' type='button'>
                            <span class="dark-text material-icons-outlined font-20">close</span></button>
                        <span>{{dayjs(file.date).format('MM/DD/YY')}} - </span>
                        <span class="dark-text">{{file.file_name}}</span>
                        <span class="dark-text">{{file.name}}{{file.type ? `.${file.type}` : ''}}</span>
                    </div>
                </div>

                <div class='flex space-between'>
                    <div>
                        <button class="margin-0 primary" type='button' @click='browse'>
                            <span class="material-icons-outlined white-text">attach_file</span> Attach a file
                        </button>
                        <span class='error'>{{browseError}}</span>
                    </div>
                    <div class="align-right">
                        <button class="secondary" type="reset" @click="onCancel">Cancel</button>
                        <button class="primary" type="submit" :disabled="isSendButtonDisabled">Send</button>
                    </div>
                </div>

                

            </form>
        </ValidationObserver>
        <ClientAttachFilesWarningModal
            :open='showMoreThanOneClientWarningModal'
            @close='handleAttachFilesWarningModalClose'
        />
        <AttachEmailDialog
            :open='showAttachEmailDialog'
            @close='handleAttachEmailDialogClose'
            @removeNormalFiles='removeNormalFilesFromDialog'
            :show-file-explorer='showFileExplorer'
            :more-than-one-client='moreThanOneClient.bool'
            :client='clientForAttachingFiles'
            :currently-client-attached-files='clientAttachedFiles'
            :company-name='companyInfo.company_name'
            :normal-files-currently-selected='normalAttachedFiles'
            ref='attachEmailDialog'
        />
    </div>
</template>

<script>
    import { ValidationObserver, validate } from 'vee-validate';
    import api from '../../util/api';
    import EmailAddressBookModal from '../../components/general/modals/EmailAddressBookModal';
    import Input from '../../components/general/validatedInputs/Input.vue';
    import Vue from 'vue';
    import { debounce } from 'lodash';

    import { UploaderPlugin } from '@syncfusion/ej2-vue-inputs';
    import Wysiwyg from '@/components/general/wysiwyg/Wysiwyg';
    import { clients, companies } from '@/util/apiRequests';
    import dayjs from '@/util/dayjs';
    import ClientAttachFilesWarningModal from '@/components/general/modals/ClientAttachFilesWarningModal';
    import AttachEmailDialog from '@/components/general/modals/AttachEmailDialog';
    Vue.use(UploaderPlugin);

    export default {
        name: 'EmailEditor',
        components: { AttachEmailDialog, ClientAttachFilesWarningModal, Wysiwyg, ValidationObserver, Input, EmailAddressBookModal },
        props: {
            address: {
                type: String,
                required: false,
                default: ''
            },
            modal_client_id: {
                type: [String, Number],
                required: false,
                default: null
            },
            modal_client_guardian_email: {
                type: String,
                required: false,
                default: null
            },
        },
        data() {
            return {
                clientToChart: null,
                clientContacts: [],
                shareToClientChart: false,
                loadingAssociatedClient: false,
                email: {
                    from: 'noreply@mobexhealth.net',
                    toAddresses: [],
                    ccAddresses: [],
                    bccAddresses: [],
                    subject: undefined,
                    html: undefined,
                    text: undefined
                },
                errors: {
                    to: [],
                    cc: [],
                    bcc: [],
                    files: []
                },
                emailContacts: [],
                list_templates: [],
                efield: {},
                button: {
                    browse: 'Browse'
                },
                fileValidation: {
                    maxSizeBytes: 7340032, // Sum of file sizes
                    disallowedExtensions: ['exe']
                },
                browseError: "",
                showBrowseModal: false,
                showMoreThanOneClientWarningModal: false,
                removeableEmail: null,
                showAttachEmailDialog: false,
                showCC: false,
                showBCC: false,
                clientAttachedFiles: [],
                normalAttachedFiles: [],
                companyInfo: {},
                showFileExplorer: false,
                dayjs,
                isSendButtonDisabled: false


            };
        },
        computed: {

            attachedFiles(){
                return [...this.normalAttachedFiles, ...this.clientAttachedFiles];
            },
            header() {
                return this.headerText || this.pageMeta.title;
            },
            hasClientFilesAttached(){
                if(this.clientAttachedFiles.length > 0){
                    return true;
                }

                return false;
            },
            //This computed property for client we will attach files to, whenever there is more than one client, this is no longer eligible
            //and needs to be reset to null
            clientForAttachingFiles(){
                if(this.moreThanOneClient?.bool == false && this.moreThanOneClient?.value.length == 1){
                    return this.moreThanOneClient.value[0];
                }
                return null;
            },
            moreThanOneClient() {


                let maxLengthArray = [this.email.toAddresses.length
                    , this.email.ccAddresses.length
                    , this.email.bccAddresses.length
                ];
                let max = Math.max(...maxLengthArray);
                let thereIsMoreThanOneClient = false;
                let clients = [];
                //loop through all three arrays
                //if there is more than one client in any of the arrays, set thereIsMoreThanOneClient to true
                //and add the client to the clients array
                //break out of the loop when thereIsMoreThanOneClient is true
                //I built it like this for a scenario incase someone selects literally every email in the multiselect
                //in my experience any other type of logic would be too slow and freeze the browser in case the above occurs.
                for(let i = 0; i < max; i++) {
                    if(this.email.toAddresses[i] !== undefined) {
                        if(this.email.toAddresses[i].type === 'client') {
                            if(clients.some((e) => e.id === this.email.toAddresses[i].id)) {
                                //console.log('client already in array');
                            } else {
                                clients.push(this.email.toAddresses[i]);
                            }
                        }
                        if(clients.length > 1) {

                            thereIsMoreThanOneClient = true;
                            break;
                        }
                    }
                    if(this.email.ccAddresses[i] !== undefined) {
                        if(this.email.ccAddresses[i].type === 'client') {
                            // clients.push(this.email.ccAddresses[i]);
                            if(clients.some((e) => e.id === this.email.ccAddresses[i].id)) {
                                //client already in array
                            } else {
                                //client not in array;
                                clients.push(this.email.ccAddresses[i]);
                            }
                        }
                        if(clients.length > 1) {
                            thereIsMoreThanOneClient = true;
                            break;
                        }
                    }
                    if(this.email.bccAddresses[i] !== undefined) {
                        if(this.email.bccAddresses[i].type === 'client') {
                            // clients.push(this.email.bccAddresses[i]);
                            if(clients.some((e) => e.id === this.email.bccAddresses[i].id)) {
                                //client already in array
                            } else {
                                //client not in array
                                clients.push(this.email.bccAddresses[i]);
                            }
                        }
                        if(clients.length > 1) {
                            thereIsMoreThanOneClient = true;
                            break;
                        }
                    }
                }

                if(this.clientToChart) {
                    clients.push(this.clientToChart);
                }
                //remove duplicates by id and check again
                clients = clients.filter((client, index, self) =>
                    index === self.findIndex((t) => (
                        t.id === client.id
                    ))
                );
                if(clients.length > 1) {
                    thereIsMoreThanOneClient = true;
                }else {
                    thereIsMoreThanOneClient = false;
                }
                return {bool: thereIsMoreThanOneClient, value: clients};
            }
        },
        methods: {
            async getCompany() {
                try {
                    let result = await this.$api.get(companies.getCompanyInfo());
                    this.companyInfo = result.data[0];
                } catch (err) {
                    console.log(err);
                }
            },
            removeNormalFilesFromDialog(payload) {
                this.normalAttachedFiles = this.normalAttachedFiles.filter((file) => {
                    //remove everything after the first period from file name
                    payload.name = payload.name.substring(0, payload.name.indexOf('.'));
                    let copy = JSON.parse(JSON.stringify(file));
                    copy.name = copy.name.substring(0, copy.name.indexOf('.'));

                    if(copy.name === payload.name && copy.size === payload.size && copy.type === payload.type) {
                        return false;
                    } else {
                        return true;
                    }
                });
                this.showFileExplorer = false;
                //temporary fix for file explorer, literally the best I can do for now since it's syncfusion...
                setTimeout(() => {
                    this.showFileExplorer = true;
                    this.$forceUpdate();
                }, 50);

            },
            removeFile(file) {
                if(this.clientAttachedFiles.some(e => e.id === file.id)) {
                    this.clientAttachedFiles = this.clientAttachedFiles.filter(e => e.id !== file.id);
                }
                if(this.normalAttachedFiles.some(e => e.id === file.id)) {
                    this.normalAttachedFiles = this.normalAttachedFiles.filter(e => e.id !== file.id);
                }
            },
            handleAttachEmailDialogClose(action, payload) {
                this.showAttachEmailDialog = false;
                this.showFileExplorer = false;
                if(action === 'attachingClientFiles') {

                    this.clientAttachedFiles = payload;
                }
                if(action === 'attachingNormalFiles') {

                    //Everytime a new file is uploaded it resets the rawFile and ID from the previous one
                    //So what we're left with is the name, size, and type of the file. This is what we'll use to compare to the current attachments
                    //If the file is already attached, we'll just ignore it.
                    //If it's not attached, we'll add it to the list of attachments.
                    payload.forEach((file) => {
                        if(this.normalAttachedFiles.some(e => e.name === file.name && e.size === file.size && e.type === file.type) === false) {
                            this.normalAttachedFiles.push(file);
                        }
                    });
                    // this.normalAttachedFiles = payload;
                }
                if(action === 'attachClientFilesAndNewClient'){

                    //find the email address and add it to the list
                    let client = this.emailContacts.find(e => e.id === payload.client.id && e.type === 'client');

                    //if client found, add it to the list
                    if(client) {
                        this.email.toAddresses.push(client);
                        this.handleToEmailSelectEvent(client);
                        this.clientAttachedFiles = payload.clientAttachedFiles;

                    }
                }

            },
            handleAttachFilesWarningModalClose(action, payload){
                if(action == 'submit'){

                    this.clientAttachedFiles = [];
                    //remove from client attached files
                }else {

                    //I did not want to do this, but I forgot about the address book that allows multiple email selections at once.
                    //this logic will actually only fire off when clients are selected through the address book.
                    //all other edgecases will be handled as normally.
                    if(Array.isArray(this.removeableEmail.value) && this.removeableEmail.value.length > 0){
                        this.removeableEmail.value.forEach(e => {
                            if(this.email.toAddresses.findIndex(to => to.email === e.email) > -1){
                                this.email.toAddresses.splice(this.email.toAddresses.findIndex(to => to.email === e.email), 1);
                                this.handleToEmailRemoveEvent(e);
                            }

                            if(this.email.ccAddresses.findIndex(cc => cc.email === e.email) > -1){
                                this.email.ccAddresses.splice(this.email.ccAddresses.findIndex(cc => cc.email === e.email), 1);
                            }

                            if(this.email.bccAddresses.findIndex(bcc => bcc.email === e.email) > -1){
                                this.email.bccAddresses.splice(this.email.bccAddresses.findIndex(bcc => bcc.email === e.email), 1);
                            }
                            if(this.clientToChart?.email === e.email) {
                                this.clientToChart = null;
                            }


                        });

                        //reset back to null;
                        this.removeableEmail = null;
                        this.showMoreThanOneClientWarningModal = false;

                        //I don't like to early return but in this case I will
                        return;
                    }

                    if(this.removeableEmail.type == 'to') {
                        this.email.bccAddresses = this.email.bccAddresses.filter(e => e.email !== this.removeableEmail.value.email)
                        this.email.toAddresses = this.email.toAddresses.filter(e => e.email !== this.removeableEmail.value.email)
                        this.handleToEmailRemoveEvent(this.removeableEmail.value);
                        this.email.ccAddresses = this.email.ccAddresses.filter(e => e.email !== this.removeableEmail.value.email)


                    }
                    if(this.removeableEmail.type == 'cc') {
                        this.email.bccAddresses = this.email.bccAddresses.filter(e => e.email !== this.removeableEmail.value.email)
                        this.email.toAddresses = this.email.toAddresses.filter(e => e.email !== this.removeableEmail.value.email)
                        this.handleToEmailRemoveEvent(this.removeableEmail.value);
                        this.email.ccAddresses = this.email.ccAddresses.filter(e => e.email !== this.removeableEmail.value.email)

                        if(this.clientToChart?.email === this.removeableEmail.value.email) {
                            this.clientToChart = null;
                        }
                    }
                    if(this.removeableEmail.type == 'bcc') {
                        // this.email.bccAddresses.splice(this.email.bccAddresses.findIndex(e => e.email === this.removeableEmail.value.email), 1);
                        this.email.bccAddresses = this.email.bccAddresses.filter(e => e.email !== this.removeableEmail.value.email)
                        this.email.toAddresses = this.email.toAddresses.filter(e => e.email !== this.removeableEmail.value.email)
                        this.handleToEmailRemoveEvent(this.removeableEmail.value);
                        this.email.ccAddresses = this.email.ccAddresses.filter(e => e.email !== this.removeableEmail.value.email)

                        if(this.clientToChart?.email === this.removeableEmail.value.email) {
                            this.clientToChart = null;
                        }
                    }
                    if(this.removeableEmail.type == 'share'){
                         this.email.bccAddresses = this.email.bccAddresses.filter(e => e.email !== this.removeableEmail.value.email)
                        this.email.toAddresses = this.email.toAddresses.filter(e => e.email !== this.removeableEmail.value.email)
                        this.handleToEmailRemoveEvent(this.removeableEmail.value);
                        this.email.ccAddresses = this.email.ccAddresses.filter(e => e.email !== this.removeableEmail.value.email)
                        this.clientToChart = null;

                    }

                }
                this.showMoreThanOneClientWarningModal = false;
            },
            browse() {
                this.showAttachEmailDialog = true;
                this.showFileExplorer = true;
            },
            debounceFindAssociateClient: debounce(function(search){
               this.findAssociatedClient(search);
            }, 500),
            async findAssociatedClient(search){
                this.loadingAssociatedClient = true;
                let body = {
                    criteria: {
                        search: {like_all: search}
                    }
                }
                let res = await this.$api.post(clients.getList(), body);
                this.clientContacts = res.data.rows;
                if(this.clientContacts.length > 0){
                   this.clientContacts = this.clientContacts.map(e => {
                        e.dob = dayjs(e.dob).utc().format('MM-DD-YYYY');
                        return e;
                    });
                }
                this.loadingAssociatedClient = false;
            },
            onFileSelect(args) {
                let files = args.filesData;
                files = files.concat(this.getFiles());
                let totalSize = 0;
                this.errors.files = [];
                for (let file of files) {
                    if (this.fileValidation.disallowedExtensions.indexOf(file.type) !== -1) {
                        this.errors.files.push(`"${file.type}" file type is not allowed`);
                    }
                    totalSize += file.size;
                }
                if (totalSize > this.fileValidation.maxSizeBytes) {
                    this.errors.files.push('Total selected file size exceeds limit of 7 MB');
                }
                if (this.errors.files.length > 0) {
                    args.cancel = true;
                    args.isModified = true;
                }
            },
            handleAddSelectedRecordsForEmailProperty(e, emailProperty) {
                if (e?.length) {
                    // if(this.modal_client_id){
                    //
                    // }
                    //before adding all these wonderful emails, let's check if we have attached client files
                    if(this.clientAttachedFiles.length > 0){
                        let clientEmailsToRemove = e.filter(e => e.type === 'client' && e.id !== this.clientForAttachingFiles.id);
                        this.removeableEmail = {value: clientEmailsToRemove, property: emailProperty};
                        this.showMoreThanOneClientWarningModal = true;
                    }

                    for (let record of e) {
                        this.addRecordToEmailProperty(record, emailProperty);
                    }
                    if (e[0] && emailProperty === 'toAddresses') {
                        this.handleToEmailSelectEvent(e[0]);
                    }
                }
            },
            addRecordToEmailProperty(record, emailProperty) {
                let emailObj = this.makeMultiselectEmailAddressObj(
                    record.email,
                    record.id,
                    record.first_name,
                    record.last_name,
                    record.type
                );
                this.email[emailProperty].push(emailObj);
                return emailObj;
            },
            async handleToEmailRemoveEvent(e) {
                // Update efield.client if applicable
                if (e?.type && e.type === 'client' && e.id === this.efield.client.id) {
                    delete this.efield.client;
                    const clientID = this.getFirstClientIDFromToAddresses(e.id);
                    await this.get_client(clientID);
                    this.updateTemplate();
                }
            },
            getFirstClientIDFromToAddresses(ignoreID = null) {
                for (let email of this.email.toAddresses) {
                    if (email.type === 'client' && email.id !== ignoreID) {
                        return email.id;
                    }
                }
                return null;
            },
            updateTemplate() {
                let el = document.getElementById('templateSelect');
                let index = el.value;
                this.pick_template(index);
            },
            async handleToEmailSelectEvent(e) {
                // Update efield.client if applicable
                if (!this.efield.client && e.type === 'client') {
                    await this.get_client(e.id);
                    this.updateTemplate();
                }
                if(e.type === 'client' && (Array.isArray(this.removeableEmail?.value) == false)) {
                    this.removeableEmail = { type: 'to', value: e };
                }

                if(this.checkIfNewClientAdded(e) && this.hasClientFilesAttached && (Array.isArray(this.removeableEmail?.value) == false)){
                    this.showMoreThanOneClientWarningModal = true;
                }
            },

            validateThenAddEmailAddress(dataEmailProperty, dataErrorsProperty, newEmailAddress) {
                validate(newEmailAddress, 'email').then((result) => {
                    if (result.valid) {
                        this.addAnEmailAddressToEmailContacts(newEmailAddress);
                        this.addAnEmailAddressToEmailProperty(newEmailAddress, dataEmailProperty);
                    }
                    this.errors[dataErrorsProperty] = result.errors;
                });
            },
            addAnEmailAddressToEmailContacts(emailAddress) {
                let emailObj = this.makeMultiselectEmailAddressObj(emailAddress);
                this.emailContacts.push(emailObj);
            },
            addAnEmailAddressToEmailProperty(emailAddress, emailProperty) {
                let emailObj = this.makeMultiselectEmailAddressObj(emailAddress);
                this.email[emailProperty].push(emailObj);
            },
            makeMultiselectEmailAddressObj(
                emailAddress,
                id = null,
                first_name = null,
                last_name = null,
                type = 'other'
            ) {
                return {
                    id,
                    first_name,
                    last_name,
                    track_id: this.emailContacts.length + 1,
                    email: emailAddress,
                    type
                };
            },
            removeShareToClient(e){
                this.clientToChart = null;
            },
            associatedClientLabel({first_name, last_name, dob}){
                let label = '';
                if(first_name && last_name){
                    label += `${first_name} ${last_name}`;
                }
                else if(first_name){
                    label += `${first_name}`
                }else if(last_name){
                    label += `${last_name}`
                }
                if(dob){
                    label += ` ${dayjs(dob).format('MM-DD-YYYY')}`
                }
                return label;
            },
            emailOptionLabel({ email, first_name, last_name, dob }) {
                let label = '';
                if (!email) {
                    return label;
                } else {
                    label = email;
                    if (first_name && last_name) {
                        label += `, ${first_name} ${last_name}`;
                    } else if (first_name) {
                        label += `, ${first_name}`;
                    } else if (last_name) {
                        label += `, ${last_name}`;
                    }
                    return label;
                }
            },
            onCancel() {
                this.$emit('close-modal');
                this.resetForm();
            },
            resetForm() {
                this.clientToChart = null;
                this.resetTemplate();
                this.resetEmailData();
                this.resetEmailErrors();
                this.resetFileUploadInput();
                // Wait until the models are updated in the UI
                this.$nextTick(() => {
                    this.$refs.form.reset();
                });
                if(this.modal_client_id){
                    this.initModalClientId();
                }
            },
            resetTemplate() {
                document.getElementById('templateSelect').selectedIndex = 0;
                delete this.efield.client;
            },
            resetEmailData() {
                Object.assign(this.$data.email, this.$options.data.call(this).email);
            },
            resetEmailErrors() {
                Object.assign(this.$data.errors, this.$options.data.call(this).errors);
            },
            resetFileUploadInput() {
                // this.$refs.fileupload.clearAll();
                this.clientAttachedFiles = [];
                this.normalAttachedFiles = [];
            },
            async onSubmit() {
                const formData = this.getFormData();

                if (formData.get('to').length === 0) {
                    this.errors.to.push('To is required');
                    return;
                }
                
                this.isSendButtonDisabled = true;
                const res = await api.post('/email/send-email', formData, {
                    params: {
                        onlySingleClient: this.modal_client_id ? true : false,
                        clientEmailNotToSend: this.clientToChart && this.shareToClientChart ? this.clientToChart.email : undefined
                    }
                });
                if (res.status === 200) {
                    this.$toasted.success('Message sent.');
                    this.resetForm();
                } else {
                    this.$toasted.error('Failed to send message.');
                }
                this.isSendButtonDisabled = false;
            },
            getClientFiles(){
                let files = this.clientAttachedFiles;
                return files;
            },
            getFormData() {
                const formData = new FormData();
                let files = this.getFiles();
                let email = this.getMessage();
                let getClientFiles = this.getClientFiles();
                for (let i = 0; i < files.length; i++) {
                    let file = files[i];
                    formData.append('uploadedFiles', file.rawFile, file.name);
                }
                if(this.clientForAttachingFiles) {

                    formData.append('clientFiles', getClientFiles.map((file) => file.id));
                    formData.append('clientId', this.clientForAttachingFiles.id);
                }
                for (let field in email) {
                    formData.append(field, email[field]);
                }

                return formData;
            },
            getFiles() {
                let files = this.normalAttachedFiles;
                return files;
            },
            getMessage() {
                return {
                    to: this.getAddresses(this.email.toAddresses),
                    cc: this.getAddresses(this.email.ccAddresses),
                    bcc: this.getAddresses(this.email.bccAddresses),
                    subject: this.email.subject,
                    html: this.email.html,
                    text: this.email.text
                };
            },
            getAddresses(arr) {
                let addresses = [];
                for (let el of arr) {
                    addresses.push(el.email);
                }
                return addresses;
            },
            goto_address_book() {
                this.$router.push({ name: 'EmailAddressBook' });
            },
            async init() {
                this.efield.user = await this.get_user(this.$store.state.user.id); // get the user logged in

                let subdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;
                if (subdomain == 'dev-client') {
                    subdomain = 'dev';
                }
                this.efield.link = {
                    portal: 'https://' + subdomain + '-portal.mobexhealth.net/auth/sign-in',
                    resources: 'https://birchnotes.com/resources' // get these from company settings?
                };

                if (this.$route.params) {
                    // if used address book or other incoming data
                    if (this.$route.params.address) {
                        this.addAnEmailAddressToEmailProperty(this.$route.params.address, 'toAddresses');
                        this.addAnEmailAddressToEmailContacts(this.$route.params.address);
                    }
                    if (this.$route.params['client_id']) {
                        await this.get_client(this.$route.params['client_id']);
                    }
                    if (this.$route.params['to_user_id']) {
                        this.efield.to_user = await this.get_user(this.$route.params['to_user_id']); // if address book is user
                    }
                }

                //These are props, for when used in a modal
                await this.get_company();
                await this.load_email_contacts();
                await this.load_email_templates();
                await this.getCompany();
                //These are props, for when used in a modal
                if (this.modal_client_id) {
                    this.initModalClientId();

                }
            },
            initModalClientId(){
                if(this.emailContacts.some(contact => contact.id == this.modal_client_id)){
                    let email = this.emailContacts.find(contact => contact.id === this.modal_client_id && (contact.type === 'client'));
                    let guardian_email = null;
                    if (this.modal_client_guardian_email) {
                        guardian_email = this.emailContacts.find(contact => contact.email === this.modal_client_guardian_email);
                    }
                    this.email.toAddresses = (guardian_email ? [guardian_email] : [email]);
                    this.efield.client = email;
                }
            },
            async load_email_templates() {
                const body = {
                    criteria: {
                        page: {
                            num_per_page: '100'
                        }
                    }
                };
                const res = await this.$api.post(`/email_templates`, body);
                this.list_templates = res.data.rows;
            },
            async pick_template(ndx) {
                this.email.text = undefined;
                this.email.html = undefined;
                this.email.subject = undefined;
                const list_template = this.list_templates.filter(e => e.id == ndx)
                if (list_template && list_template.length && list_template[0].html) {
                    let template_html = list_template[0].html.toString();
                    // template_html = template_html.replace(/<br *\/?>/gi, '\n');
                    // template_html = template_html.replace(/\n[sS]*$/,"")
                    let regex = /{efield[a-z_.]*}/gim;
                    let key, str, keys, i, newval, match;
                    const matches = [...template_html.matchAll(regex)];
                    for (match of matches) {
                        str = match[0];
                        key = str.slice(8, -1);
                        keys = key.split('.');
                        newval = {};
                        newval = Object.assign(newval, this.efield);
                        for (i in keys) {
                            if (newval[keys[i]]) {
                                newval = newval[keys[i]];
                            } else {
                                newval = str;
                                break;
                            }
                        }
                        newval = newval.toString();
                        template_html = template_html.replace(str, newval);
                    }
                    this.email.html = template_html;
                    this.email.subject = list_template[0].email_template_title;
                }
            },
            async get_company() {
                let companyId = this.$store.state.user.company_id;
                const res = await this.$api.get(`/company/getCompany/${companyId}`);
                this.efield.company = res.data.info[0];
            },
            async get_user(id) {
                const res = await this.$api.get(`/users/${id}`);
                return res.data;
            },
            async get_client(id) {
                if (id !== null) {
                    const res = await this.$api.get(`/clients/${id}/general-info`);
                    if (res.data) {
                        this.efield.client = res.data[0];
                    }
                }
            },
            async load_email_contacts() {
                const body = {
                    criteria: {
                        page: {
                            num_per_page: '99999'
                        }
                    }
                };
                const res = await this.$api.post(`/email/address-book`, body);
                res.data.rows.forEach((el, index) => {
                    el.track_id = index;
                });
                this.emailContacts = res.data.rows;
                this.emailContacts = this.emailContacts.filter(contact => {
                    if(this.modal_client_id) {
                        if (contact.type === 'client' && contact.id === this.modal_client_id) {
                             return true;
                        }else if(contact.type !== 'client'){
                            return true;
                        }
                    }else{
                        return true;
                    }
                })
            },

            handleBCCEmailChange(value){
                if(value.type == 'client' && (Array.isArray(this.removeableEmail?.value) == false)) {
                    this.removeableEmail = { type: 'bcc', value: value };
                }
                if(this.checkIfNewClientAdded(value) && this.hasClientFilesAttached && (Array.isArray(this.removeableEmail?.value) == false)){
                    this.showMoreThanOneClientWarningModal = true;
                }
            },
            handleCCEmailChange(value) {
                if(value.type == 'client' && (Array.isArray(this.removeableEmail?.value) == false)) {
                    this.removeableEmail = { type: 'cc', value: value };
                }
                if(this.checkIfNewClientAdded(value) && this.hasClientFilesAttached && (Array.isArray(this.removeableEmail?.value) == false)){
                    this.showMoreThanOneClientWarningModal = true;
                }
            },
            handleShareToClientChange(value){
                //if(this.)
                value.type = 'client';
                if(value.type == 'client' && (Array.isArray(this.removeableEmail?.value) == false)) {
                    this.removeableEmail = { type: 'share', value: value };
                }
                if(this.checkIfNewClientAdded(value) && this.hasClientFilesAttached && (Array.isArray(this.removeableEmail?.value) == false)){
                    this.showMoreThanOneClientWarningModal = true;
                }
            },
            checkIfNewClientAdded(value) {
                let maxLengthArray = [this.email.toAddresses.length
                    , this.email.ccAddresses.length
                    , this.email.bccAddresses.length
                ];
                let max = Math.max(...maxLengthArray);
                let thereIsMoreThanOneClient = false;
                for(let i = 0; i < max; i++) {
                    if(this.email.toAddresses[i] !== undefined) {
                        if(this.email.toAddresses[i].type === 'client' && this.email.toAddresses[i].id !== value.id && value.type === 'client') {
                            thereIsMoreThanOneClient = true;
                        }
                    }
                    if(this.email.ccAddresses[i] !== undefined) {
                        if(this.email.ccAddresses[i].type === 'client' && this.email.ccAddresses[i].id !== value.id && value.type === 'client')  {
                            thereIsMoreThanOneClient = true;
                        }
                    }
                    if(this.email.bccAddresses[i] !== undefined) {
                        if(this.email.bccAddresses[i].type === 'client' && this.email.bccAddresses[i].id !== value.id  && value.type === 'client') {
                            thereIsMoreThanOneClient = true;
                        }
                    }
                }
                //if the above is false
                if(this.clientToChart){
                    //when the value passed is a type BUT not equal to the shareToClientChart
                    if(this.clientToChart.id !== value.id && value.type == 'client'){
                        thereIsMoreThanOneClient = true;
                    }
                }
                return thereIsMoreThanOneClient;
            },

        },
        async mounted() {
            await this.init();
        },
         watch: {
            // c
             clientForAttachingFiles: {
                 handler(newValue, oldValue) {
                     if(this.moreThanOneClient.value.length <= 0){
                         this.clientAttachedFiles = [];
                     }
                 },
                 deep: true
             },
            "email.toAddresses": function (val) {
                this.browseError = ""
            },
            "email.ccAddresses": function (val) {
                this.browseError = ""
            },
            "email.bccAddresses": function (val) {
                this.browseError = ""
            },

            shareToClientChart(){
                // if(this.modal_client_id){
                //     if(this.shareToClientChart == true){
                //         let { email } = this.emailContacts.find((e) => e.type == 'client' && e.id == this.modal_client_id);
                //         if(email) {
                //             this.email.toAddresses = this.email.toAddresses.filter((element) => {
                //                 if(element.email == email) {
                //                     this.clientToChart = element;
                //                     return false;
                //                     // element.email !== email
                //                 }
                //                 return true;
                //             })
                //         }
                //     }else{
                //         if(this.clientToChart){
                //             //email is not already in to address
                //             if(this.email.toAddresses.some((e) => e.email == this.clientToChart.email) == false){
                //                 this.email.toAddresses.push(this.clientToChart);
                //                 this.handleToEmailSelectEvent(this.clientToChart);
                //                 this.clientToChart = null;
                //             }
                //         }
                //     }
                // }
            }
        }

    };
</script>